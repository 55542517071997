<template>
  <div>
      <div class="top_bg_pic"></div>
      <div class="top_menu">
    <div class="top_f">
      <ul>
        <li> <router-link to="/Perents/System" class="active">系统介绍</router-link></li>
        <li> <router-link to="/Perents/Guide">申请引导</router-link></li>
        <li> <router-link to="/Perents/Commoon">常见问题</router-link></li>
        <li> <router-link to="/">网站首页</router-link></li>
      </ul>
 
    </div>
     <div class="nox">
          <keep-alive>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </keep-alive>
   </div>
    
  </div>
  
   <!-- <caudal></caudal> -->
  </div>
</template>

<script>
// import caudal from '@/views/tail'
export default {
  components: {
    //   caudal
  },
  // 定义属性
  data() {
    return {
      
    }
  },
  computed: {
  },
  watch: {},
  // 方法集合
  methods: {
    
  },
  created() {
    
  },
}
</script>

<style lang='less' scoped>
// .nox{
//     padding-bottom: 100px;
// }
.top_bg_pic {
    width: 100%;
    height: 363px;
    background: url('../../assets/img/jiankong.png') no-repeat center top;
    margin: 0px auto;
}
.top_menu {
    width: 100%;
    height: 63px;
    background-color: #f0600d;
    margin: 0px auto;
}
.top_f ul {
    margin: 0px;
    padding: 0px;
}
.top_f {
    width: 1003px;
    height: 63px;
    color: rgb(255, 255, 255);
    font-family: 微软雅黑;
    font-size: 18px;
    line-height: 63px;
    margin: 0px auto;
}
.top_f li {
    float: left;
    margin: 0px;
    border-right: 1px solid #f59158;
    height: 63px;
    font-weight: bold;
}
.top_f li a {
    font-family: 微软雅黑;
    font-size: 18px;
    color: rgb(255, 255, 255);
    display: block;
    text-decoration: none;
    padding: 0px 25px;
    color:#feffff;
    // color:aquamarine;
}
.top_f li a:hover{
    color:#ffff00;
    background-color:#cc4b00;
}
  @import '../../assets/css/parents.less';
</style>