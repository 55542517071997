<template>
 <div>
     <div class="footer bgimg"><div class="logo bgimg" id="logo"><img src="" alt=""></div>
<div class="border bgimg"></div>
<ul class="copy">
  <li>
    <span target="_blank" href="">
      网络文化经营许可证:<a target="_blank" href="https://beian.miit.gov.cn/" >琼ICP备20002958号-1</a>
    </span>
  </li>
  <li>
    <span target="_blank" href="">海南椒图科技有限公司</span>
  </li>
  <li style="color:#fff;">海南省澄迈县老城镇高新技术产业示范区海南生态软件园孵化楼四楼4001</li>
  <li style="color: #fff; margin-top: 10px">
            <a target="_blank" href=" " style="display: inline-block;text-decoration: none;height: 20px;line-height: 20px;">
          <img src="../../assets/img/gongan.png" style="float: left" />
          <p style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              <!-- color: #939393; -->
            ">粤公网安备 44010602011524号
          </p></a><span style="height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 60px;">邮箱：jiaotukeji@jiaotutech.cn</span>
          </li>
</ul>
<div class="record">
    <span href="" id="beian"></span>
  <span target="_blank" href="" class="record_bg2 bgimg" id="wenhua"></span>
</div>
<!-- <div class="tip">温馨提示：游戏产品适合18岁以上使用</div> -->
</div>
 </div>
</template>

<script>

export default {
  components: {
  },
  // 定义属性
  data() {
    return {
      
    }
  },
  computed: {
  },
  watch: {},
  // 方法集合
  methods: {
    
  },
  created() {
    
  },
}
</script>

<style lang='less' scoped>
.footer .border {
    height: 96px;
    width: 1px;
    background-image: url('../../assets/img/fgx.png');
 margin: 0 30px;
}
  .header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100px;
    color: #16cbff;
}
#beian{
    display: block;
  width: 39px;
  height: 46px;
  background-image: url("../../assets/img/dzyyzz.png");
}
.navigation{
    display: flex;
    width: 500px;
}
h1{
    font-size: 30px;
}
// .header{
//         background-color: #05040c;
// }
li{
    display: flex;
    flex: 1;
    color:#7f6878;
    font-size: 16px;
}
.navigation li a.active {
    color: #16cbff;
}
.index{
    background-image: url('../../assets/img/index.png');
    width: 100%;
    height: 677px;
}
// .footer {
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     right: 0;
// }
.footer {
    // margin-top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 162px;
    // background-color: #1b1b1b;
    background-image: url('../../assets/img/rgba.jpg');
    // background: rgba(0, 0, 0, 5%)
    //  background-repeat: no-repeat, repeat;
}
a{
    color:#fff;
}
.footer .tip {
    width: 100%;
    text-align: center;
    color: #16cbff;
}
#logo {
    height: 77px;
    width: 77px;
    background-image: url('../../assets/img/logo.png');
    margin-right: 30px;
}
#wenhua {
    display: block;
    width: 45px;
    height: 46px;
    background-image: url('../../assets/img/whjy.png');
    margin-left: 10px;
}
.record{
   padding-left: 15rem;
   display: flex;
}
span{
    color: #fff;
}
</style>