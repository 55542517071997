<template>
  <div>
     <div class="main">
    <div class="main_mid">
      <div class="left">
        <div class="tj">
          <div class="tj_title">家长监护申请流程</div>
          <div class="layout">
            <p><b>Q:什么样的情况可申请家长监护服务？</b><br>
              A:申请人需为被监护未成人的法定监护人，申请人的被监护人年龄小于18周岁，申请人需为大陆公民，不含港、澳、台人士。 <br>
            </p> <br>

            <p><b>Q：家长可通过什么途径申请家长监护服务？</b><br>
              A：家长可通过传真、信件、在线申请、邮件申请或来访的方式提交相关材料，申请家长监护服务。</p><br>

            <p><b>Q：家长可申请哪些限制？</b> <br>
              A：家长可申请游戏时间的限制，比如：每天玩几个小时、每天什么时段可以玩游戏、每周可以玩几个小时、每周什么时段可以玩游戏或完全禁止登陆游戏。</p><br>

            <p><b>Q：申请家长监护需要提供哪些资料？ </b><br>
              A：需要到网站下载《监护人信息表》、《被监护人信息表》、《网络游戏未成年人家长监护申请书》、《监护法律关系证明》。按照表格内容要求进行填写并准备监护人、被监护人身份证明以及家庭关系与监护关系证明。</p><br>

            <p><b>Q：表格是否可以不下载，按照内容写在纸上能受理吗？</b><br>
              A：为确保监护人所发送的内容准确无误，建议您下载申请表格，并且按照表格上的要求严格填写。如填写不完整，可能无法受理成功。 </p><br>

            <p><b>Q：监护人提供了户口簿，是否可以不用出具监护关系证明了？</b><br>
              A：监护人必须提供户口簿，并且也需要需要到当地派出所开具监护关系证明。 </p><br>

            <p><b>Q：申请书不签名可以吗？</b> <br>
              A：《监护人信息表》、《网络游戏未成年人家长监护申请书》、《监护法律关系证明》必须手写签名才能受理。监护人未签字，或者签字字迹潦草无法识别的都无法受理成功；被监护人如果不愿意在《被监护人信息表》上签名的，不影响申请。
            </p><br>

            <p><b>Q：被监护人的信息与游戏账号的注册信息不一致，是否能申请监护？</b><br>
              A：被监护人的信息必须与游戏账号的注册信息一致，否则此项服务将无法受理。监护人可以申请根据被监护人身份信息查询是否存在对应的游戏账号，（客服与传真处理专岗联系查询，两个工作日内由客服进行回复)</p><br>

            <p><b>Q：家长监护服务是否收费？</b></p>
            A：此项服务为免费服务<p></p><br><br>


            <p><b>Q：提交申请后多久能受理？ </b><br>
              A：在收到监护人所提供的材料后2个工作日内，由客服核对被监护人身份信息是否与游戏帐号注册信息一致，以及监护人所提供的信息是否符合服务要求。并与监护人进行电话联系。 </p><br>

            <p><b>Q：家长申请监护成功后怎么查询处理进度？</b> <br>
              A：家长可以在网站自助查询处理进度 </p>
            <div class="clear"></div>
          </div>
          <div class="layout">
            <p>&nbsp;</p>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div class=" right">
        <div class="kaifu">
          <!-- <div class="k_title"> 咨询热线</div>
        <div class="k_nr">
          <p><b>服务热线：</b>021-31128134</p>
          <p><b>&nbsp;服务传真：</b>021-31128134 </p>
        </div> -->
        </div>
        <div class="kaifu">
          <div class="k_title">常见问题</div>
          <div class="k_nr">
            <ul class="t" style=" margin-left:5px;">
              <li><a href="javascript:void(0)">&nbsp;&nbsp;&nbsp; 什么样的情况可申请家长监护服务？</a>
              </li>
              <li>&nbsp;&nbsp;<a href="javascript:void(0)">&nbsp; 家长可通过什么途径申请家长监护服务？</a></li>
              <li>&nbsp;&nbsp;<a href="javascript:void(0)">&nbsp; 家长可申请哪些限制？ </a></li>
            </ul>
            <div class="k_nr_font"><a href="./family-4.html">更多问题&gt;&gt;</a></div>
          </div>
        </div>
        <div class="kaifu">
          <div class="k_title"> 申请资料下载</div>
          <div class="k_nr">
            <ul class="t" style=" margin-left:5px;">
              <li>&nbsp;&nbsp;&nbsp; <a href="./assets/网站家长监护doc1.doc">文件一：<span class="k_nr_blue">《监护人信息表》 </span></a>
              </li>
              <li>&nbsp;&nbsp;&nbsp;<a href="./assets/网站家长监护doc2.doc"> 文件二：<span class="k_nr_blue">《被监护人信息表》</span></a>
              </li>
              <li>&nbsp;&nbsp;&nbsp; <a href="./assets/网站家长监护doc3.doc">文件三：<span class="k_nr_blue">《家长监护申请书》
                  </span></a>
              </li>
            </ul>

          </div>
        </div>
        <div class=" clear"></div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="clear"></div>
  </div>
   <!-- <caudal></caudal> -->
    <div class="box">
        <caudal></caudal>
  </div>
  </div>
</template>

<script>
import caudal from '@/views/tail'
export default {
  components: {
      caudal
  },
  // 定义属性
  data() {
    return {
      
    }
  },
  computed: {
  },
  watch: {},
  // 方法集合
  methods: {
    
  },
  created() {
    
  },
}
</script>

<style lang='less' scoped>
// .box{
//     padding-top: 1000px;
// }
 @import '../../assets/css/parents.less';

</style>