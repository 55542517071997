<template>
  <div>
     <head-bar :routerData="transmitData"></head-bar>
      <div>
      <el-carousel :interval="4000" indicator-position="outside" height="350px" autoplay>
        <el-carousel-item v-for="(img,index) in imgList" :key="index">
          <img v-bind:src="img.url" />
        </el-carousel-item>
      </el-carousel>
    </div>
     <caudal ></caudal>
  </div>
</template>

<script>
import HeadBar from '@/views/head'
import caudal from '@/views/footer'

export default {
  components: {
        HeadBar,caudal
  },
  // 定义属性
  data() {
    return {
      imgList: [
        { url: require("../../assets/img/banner.jpg") },
        // { url: require("../../assets/img/王者纷争banner.jpg") },
        // { url: require("../../assets/img/百恋成仙banner.jpg") }
      ],
      transmitData:{
             company:true
            }
    }
  },
  computed: {
  },
  watch: {},
  // 方法集合
  methods: {
    
  },
  created() {
    
  },
}
</script>

<style lang='less' scoped>
   .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>