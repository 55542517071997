// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import index from '../views/index'
// import Home from '../views/home'

// Vue.use(VueRouter)

// const routes = [{
//         path: '/',
//         name: 'Home',
//         component: Home
//     },
//     {
//         path: '/index',
//         name: 'index',
//         component: index
//     },
//     // {
//     //     path: '/about',
//     //     name: 'About',
//     //     // route level code-splitting
//     //     // this generates a separate chunk (about.[hash].js) for this route
//     //     // which is lazy-loaded when the route is visited.
//     //     component: () =>
//     //         import ( /* webpackChunkName: "about" */ '../views/About.vue')
//     // }
// ]

// const router = new VueRouter({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes
// })

// export default router
import Vue from 'vue'
import Router from 'vue-router'
import Index from '../views/index'
// import Home from '@/views/home'
import Company from '@/views/company'
import Ganme from '@/views/ganme'
import Perents from '@/views/parents'
import Commoon from '../views/common'
import System from '../views/system'
import Guide from '../views/guide'

Vue.use(Router)


const router = new Router({
    // mode: 'history',
    routes: [{
            path: '/',
            name: 'Index',
            component: Index,

        },
        {
            path: '/Company',
            name: 'Company',
            component: Company,


        },
        {
            path: '/Ganme',
            name: 'Ganme',
            component: Ganme,


        },
        {
            path: '/Perents',
            name: 'Perents',
            component: Perents,
            children: [{
                    path: '/Perents/Commoon',
                    name: '常见问题',
                    component: Commoon,
                },
                {
                    path: '/Perents/Guide',
                    name: '申请引导',
                    component: Guide,
                },
                {
                    path: '/Perents/System',
                    name: '系统介绍',
                    component: System,
                },
            ]


        },


    ]
});
router.beforeEach((to, from, next) => {
    // 登录界面登录成功之后，会把用户信息保存在会话
    // 存在时间为会话生命周期，页面关闭即失效。
    // console.log(token)
    // console.log(token)
    if (to.path === '/perents') {
        next({ path: '/perents/system' })
            // 如果是访问登录界面，如果用户会话信息存在，代表已登录过，跳转到主页

    } else {
        next()
    }
})

export default router