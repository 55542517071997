<template>
  <div class="index">
 <div class="header">
      <h1 class="bgimg"><img src="../../assets/img/title.png" alt=""></h1>
      <ul class="navigation">
        <li> <router-link to="/" v-bind:class="{ active: index }">首页</router-link></li>
        <!-- <li> <router-link to="/company" v-bind:class="{ active: company }">公司介绍</router-link></li> -->
        <li> <router-link to="/ganme" v-bind:class="{ active: ganme }">游戏&公司介绍
</router-link></li>
        <li> <router-link to="/perents" v-bind:class="{ active: perents }">家长监护</router-link></li>
        <!-- <li><a href="./index.html" num="1" class="active">首页</a></li>
        <li><a href="./company.html" num="2">公司介绍</a></li> -->
      </ul>
    </div>
  </div>
</template>

<script>

export default {
    props:{
        routerData:{
            type:Boolean
        }
    },
  components: {
  },
  // 定义属性
  data() {
    return {
        index:this.routerData.index2,
        company:this.routerData.company,
        ganme:this.routerData.ganme,
        perents:this.routerData.perents,
        
    }
  },
  computed: {
  },
  watch: {},
  // 方法集合
  methods: {
      open(){
          alert(11111)
      }
    
  },
  created() {
    
  },
}
</script>

<style lang='less' scoped>
.navigation li a:hover {
    color: yellow;
}
  .header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100px;
    color: #16cbff;
    background-image: url('../../assets/img/bg.jpg');
}
.navigation{
    display: flex;
    width: 500px;
}
h1{
    font-size: 30px;
}
// .header{
//         background-color: #05040c;
// }
li{
    display: flex;
    flex: 1;
    color:#fff;
    font-size: 16px;
}
.navigation li a.active {
    color: aquamarine;
}
.index{
    // background-image: url('../../assets/index.png');
    width: 100%;
    // height: 677px;
}
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 260px;
    background-color: #1b1b1b;
}
a{
    color:#fff;
}
.footer .tip {
    width: 100%;
    text-align: center;
    color: #16cbff;
}
.top_f li a {
    font-family: 微软雅黑;
    font-size: 18px;
    color: rgb(255, 255, 255);
    display: block;
    text-decoration: none;
    padding: 0px 25px;
    color:#feffff;
}
</style>