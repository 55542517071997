<template>
  <div>
    <div class="main">
      <div class="main_mid">
        <div class="left">
          <div class="tj">
            <div class="tj_title">工程介绍</div>
            <div class="layout">
              <img
                src="../../assets/img/gongc.png"
                width="100"
                height="130"
                style="margin-top: 60px; float: left"
              />
              <h2>
                网络游戏未成年人家长监护工程"是一项由中华人民共和国文化部指导，网络游戏企业共同发起并参与实施，旨在
                加强家长对未成年人参与网络游戏的监护，引导未成年人健康、绿色参与网络游戏，和谐家庭关系的社会性公益行动。<br />
                "家长监护机制"这一公益行动中，针对目前未成年人缺乏自控及自律能力，容易陷入沉迷；少数监护人缺少时间照顾孩子，不能及时监督孩子游戏时间的现状，而推出的一种可由家长实施监控，纠正部分未成年子女沉迷游戏的保护机制。
              </h2>
              <div class="clear"></div>
            </div>
            <div class="tj_title">供申请监督方式：</div>
            <div class="layout">
              <p>A. 屏蔽账号：根据监护人意愿屏蔽相关游戏应用登陆。</p>
              <p>B. 账号动态监控：登陆、修改密码等操作将发短信至监护人手机。</p>
              <p>1）账号已经有认证手机的，将监护人手机替换为认证手机；</p>
              <p>2）账号未认证手机，将监护人手机绑定成认证手机；</p>
              <div class="clear"></div>
            </div>
            <div class="tj_title">可以选择的监护结果:</div>
            <div class="layout">
              <p>　限制被监护的游戏账号：</p>
              <p>　　1、每天玩游戏的时间长度；</p>
              <p>　　2、每周玩游戏的时间长度；</p>
              <p>　　3、每天玩游戏的时间段；</p>
              <p>　　4、完全禁止进入游戏。</p>
              <div class="la_tishi">未成年人健康参与网络游戏提示</div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
        <div class=" right">
        <div class="kaifu">
        </div>
        <div class="kaifu">
          <div class="k_title">常见问题</div>
          <div class="k_nr">
            <ul class="t" style=" margin-left:5px;">
              <li><a href="javascript:void(0)"> 什么样的情况可申请家长监护服务？</a>
              </li>
              <li><a href="javascript:void(0)"> 家长可通过什么途径申请家长监护服务？</a></li>
              <li><a href="javascript:void(0)"> 家长可申请哪些限制？ </a></li>
            </ul>
            <div class="k_nr_font"><router-link to="/Perents/Commoon">更多问题</router-link>&gt;&gt;</div>
          </div>
        </div>
        <div class="kaifu">
          <div class="k_title"> 申请资料下载</div>
          <div class="k_nr">
            <ul class="t" style=" margin-left:5px;">
              <li><a href="./assets/网站家长监护doc1.doc">文件一：<span class="k_nr_blue">《监护人信息表》 </span></a>
              </li>
              <li><a href="./assets/网站家长监护doc2.doc"> 文件二：<span class="k_nr_blue">《被监护人信息表》</span></a>
              </li>
              <li> <a href="./assets/网站家长监护doc3.doc">文件三：<span class="k_nr_blue">《家长监护申请书》
                  </span></a>
              </li>
            </ul>

          </div>
        </div>
        <div class=" clear"></div>
      </div>
        <div class="clear"></div>
      </div>
        <div class="clear"></div>
    </div>
  <div class="box">
        <caudal></caudal>
  </div>
  </div>
</template>

<script>
import caudal from '@/views/tail'
export default {
  components: {
      caudal
      },
  // 定义属性
  data() {
    return {};
  },
  computed: {},
  watch: {},
  // 方法集合
  methods: {},
  created() {},
};
</script>

<style lang='less' scoped>
// .box{
//     padding-top: 760px;
// }
@import '../../assets/css/parents.less';
</style>