<template>
  <div>
      <div class="main">
    <div class="main_mid">
      <div class="left">
        <div class="tj">
          <div class="tj_title">一、申请条件</div>
          <div class="layout">
            <p>1、申请人需为被监护未成年人的法定监护人；</p>
            <p>2、申请人的被监护人年龄小于18周岁；</p>
            <p>3、申请人需为大陆公民，不含港、澳、台人士。</p>
            <div class="clear"></div>
          </div>
          <div class="tj_title">二、申请需要提交材料</div>
          <div class="layout">
            <p>1、监护人信息表（包含监护人的身份证明复印件）；</p>
            <p>2、被监护人信息表（包含被监护人所玩游戏相关信息及身份证明复印件）；</p>
            <p>3、填写网络游戏未成年人家长监护申请书、保证书、授权书并手工签字（需下载，填写并打印，签字）；</p>
            <p>4、申请人与被监护人的监护关系证明文件（户口簿或有关机关出具的证明文件）。</p>
            <span style=" float:right;"><a href=""><img src="../../assets/img/打包.png" width="104" height="29"></a></span>
            <div class="clear"></div>
          </div>
          <div class="tj_title">三、申请方式</div>
          <div class="layout">
            <p>服务热线（仅供咨询）： </p>
            <p>您可以通过联系电话、信件或来访的方式联系我们：</p>
            <p>1、传真服务： </p>
            <p>2、电子邮件： </p>
            <p>3、来访及信件: 广州市天河区高普路1023号1楼101自编06房 </p>
            <div class="clear"></div>
          </div>
          <div class="tj_title">四、其他要求</div>
          <div class="layout">
            <p>1.申请人应提交较完备的申请材料，对未提供的信息要及时补充；可请熟知电脑、互联网、游戏的人员进行协助，以便提供详实资料；</p>
            <p>2.申请人应保证提交的信息真实有效；对于提供虚假信息或伪造证件，我司将保留进一步追究法律责任的权利。 </p>
            <div class="clear"></div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div class=" right">
        <div class="kaifu">
        </div>
        <div class="kaifu">
          <div class="k_title">常见问题</div>
          <div class="k_nr">
            <ul class="t" style=" margin-left:5px;">
              <li><a href="javascript:void(0)">&nbsp;&nbsp;&nbsp; 什么样的情况可申请家长监护服务？</a>
              </li>
              <li>&nbsp;&nbsp;<a href="javascript:void(0)">&nbsp; 家长可通过什么途径申请家长监护服务？</a></li>
              <li>&nbsp;&nbsp;<a href="javascript:void(0)">&nbsp; 家长可申请哪些限制？ </a></li>
            </ul>
            <div class="k_nr_font"><router-link to="/Perents/Commoon">更多问题</router-link></div>
          </div>
        </div>
        <div class="kaifu">
          <div class="k_title"> 申请资料下载</div>
          <div class="k_nr">
            <ul class="t" style=" margin-left:5px;">
              <li>&nbsp;&nbsp;&nbsp; <a href="">文件一：<span class="k_nr_blue">《监护人信息表》 </span></a>
              </li>
              <li>&nbsp;&nbsp;&nbsp;<a href=""> 文件二：<span class="k_nr_blue">《被监护人信息表》</span></a>
              </li>
              <li>&nbsp;&nbsp;&nbsp; <a href="">文件三：<span class="k_nr_blue">《家长监护申请书》
                  </span></a>
              </li>
            </ul>

          </div>
        </div>
        <div class=" clear"></div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="clear"></div>
  </div>
    <div class="box">
        <caudal></caudal>
  </div>
  </div>
</template>

<script>
import caudal from '@/views/tail'
export default {
  components: {caudal
  },
  // 定义属性
  data() {
    return {
      
    }
  },
  computed: {
  },
  watch: {},
  // 方法集合
  methods: {
    
  },
  created() {
    
  },
}
</script>

<style lang='less' scoped>
@import '../../assets/css/parents.less';
</style>