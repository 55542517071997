<template>
  <div class="one">
    <head-bar :routerData="transmitData"></head-bar>
    <div>
      <el-carousel
        :interval="4000"
        indicator-position="none"
        height="350px"
        autoplay
      >
        <el-carousel-item v-for="(img, index) in imgList" :key="index">
          <img v-bind:src="img.url" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="content-out">
        <div class="content-img" style="margin-top: 100px">
          <span style="padding-right: 10px;"> <img src="../../assets/img/gsjs.png" alt="" /></span>
          <img src="../../assets/img/gszp.png" alt="" />
          <div class="tatle">
            <h3>海南椒图科技有限公司</h3>
            <div>
              <p>
                海南椒图成立于2020年，是一家以手机游戏联合运营和推广起步，致力于成为国内最优秀手机游戏发行的新锐游戏公司，核心团队汇聚了数十名拥有手游发行和运营经验。
              </p>
              <br />
              <p>
                公司专注于手机游戏发行，我们团队有着敏锐的市场洞察和精准的定位投放，自成立以来一直都在为大家推广着优质的游戏产品，其中包括有《遇见尊上》，受到广大游戏玩家的好评和追捧。海南椒图始终秉持“用游戏传递快乐”的信念，以专业的产品运营服务为玩家打造一个积极、健康的手机游戏平台。
              </p>
              <br />
              <p>
                海南椒图在未来不会止步于一家游戏发行公司的单一定位，也会努力成为一家能走进用户日常生活、承担更多用户需求的文化运营企业。
              </p>
            </div>
          </div>
        </div>

   <div class="content-img" style="margin-top: 100px">
          <span style="padding-right: 10px;"> <img src="../../assets/img/cpjs.png" alt="" /></span>
          <img src="../../assets/img/snbqt.jpeg" alt="" />
          <div class="tatle">
            <h3>少年包青天</h3>
            <div>
              <p>
                《少年包青天》是大众耳熟能详的同名影视剧IP改编的古风悬疑探案推理手游。北宋年间，各地怪案频出，离奇诡异，一时间引得人心惶惶，朝局动荡。身为开封府的一名衙内，以及皇城司的密探，你深知攘外必先安内，于是临危受命，辗转各地解谜探案。你下定决心，一定要侦破这些疑案，还百姓一个公道......
              </p>
             
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box"><caudal></caudal>
    </div>
  </div>
</template>

<script>
import HeadBar from "@/views/head";
import caudal from "@/views/footer";
export default {
  components: {
    HeadBar,
    caudal,
  },
  // 定义属性
  data() {
    return {
      imgList: [
        { url: require("../../assets/img/banner.jpg") },
      ],
      transmitData: {
        ganme: true,
      },
    };
  },
  computed: {},
  watch: {},
  // 方法集合
  methods: {},
  created() {},
};
</script>

<style lang='less' scoped>
.one{
  background-image: url("../../assets/img/bg.jpg");
}
p{
    text-align: left;
    font-size: 14px;
    font-family: none;
    
}
.tatle {
  padding-left: 84px;
}
h3 {
  font-size: 20px;
  color: #f0e08a;
  text-align: left;
  padding-bottom: 10px;
}
.content-img {
  width: 100%;
  height: 268px;
//   background-color: aquamarine;
  display: flex;
}
.content-out {
  width: 968px;
  height: 1014px;
//   background-color: bisque;
}
.bxo {
  padding-top: 1000px;
}
// li.wzfz .gameBg {
//   background-image: url("../../assets/img/wangzhe.png");
//   height: 268px;
// }
// li.blcx .gameBg {
//   background-image: url("../../assets/img/bailian.png");
//   height: 268px;
// }
// li.hhsg .gameBg {
//   background-image: url("../../assets/img/snbqt.jpeg");
//   height: 268px;
// }
.gameList li {
  width: 380px;
  margin-bottom: 58px;
}
.content {
  display: flex;
  justify-content: center;
//   background-image: url("../../assets/img/bg.jpg");
}
.content .gameList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 900px;
}
.title {
  color: #16cbff;
  display: flex;
  font-size: 20px;
  font-family: "MicrosoftYaHei-Bold";
  margin-bottom: 8px;
  margin-top: 16px;
}
.text {
  // color: #fff;
  font-size: 14px;
  font-family: "MicrosoftYaHeiLight";
  text-align: left;
}
</style>